<template>
  <div>
    <shop-head currentPageName="PAY"></shop-head>
    <!-- 面包屑 -->
    <breadcrumb pageName="Pay"></breadcrumb>
    <div v-if="showAdd" class="address-box container">
      <el-form ref="form" :model="form" :rules="rules" label-position="top">
        <el-form-item prop="location">
          <el-input v-model="form.location" type="textarea" placeholder="Location*"></el-input>
        </el-form-item>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item prop="firstName">
              <el-input v-model="form.firstName" placeholder="First Name*"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="lastName">
              <el-input v-model="form.lastName" placeholder="Last Name*"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="phone">
          <el-input v-model="form.phone" placeholder="Phone Number*"></el-input>
        </el-form-item>
        <el-form-item prop="detailAddress">
          <el-input v-model="form.detailAddress"
            placeholder="ADDRESS FINDER: Search by postcode,street or address"></el-input>
        </el-form-item>
      </el-form>
      <div @click="saveAdd" class="register-btn">Save</div>
    </div>
    <div v-else class="address-list container">
      <div class="address-card">
        <div class="address-card-bd"></div>
        <div style="padding-left: 15px">
          <div class="address-card-name">
            <span>junming zhao</span>
            <span class="grey">13520369852</span>
          </div>
          <div class="address-card-text">
            1082 Pale San Vitores Rd Ste ANNEX
          </div>
          <div class="address-card-text">
            Barrigada Guam United Staes 96913-4064
          </div>
        </div>
      </div>
      <div @click="saveAdd" class="address-btn">+ADD NEW ADDRESS</div>
    </div>
    <div class="container">
      <el-table :data="productList" border style="width: 100%">
        <el-table-column prop="image" label="Images" min-width="120" align="center">
          <template slot-scope="scope">
            <el-image v-if="scope.row.imgUrl" :src="scope.row.imgUrl" :preview-src-list="scope.row.imageList"
              class="table-img">
            </el-image>
            <img v-else src="../../assets/images/empty-img.jpg" alt="productImg" class="table-img" />
          </template>
        </el-table-column>
        <el-table-column prop="partsName" label="name" min-width="150" align="center"></el-table-column>
        <el-table-column prop="partsNo" label="code" min-width="150" align="center"></el-table-column>
        <el-table-column prop="manufacturerCname" label="brand" min-width="150" align="center"></el-table-column>
        <el-table-column prop="sellPrice" label="Unit Price" align="center">
          <template slot-scope="scope">
            <span>${{ $utils.keepTwoDecimalFull(scope.row.sellPrice) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="quantity" label="Quantity" align="center"></el-table-column>
        <el-table-column prop="total" label="Total" align="center">
          <template slot-scope="scope">
            <span>${{
                $utils.keepTwoDecimalFull(
                  $utils.accMul(scope.row.sellPrice, scope.row.quantity)
                )
              }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="cart-area">
        <el-row justify="end" type="flex">
          <el-col :sm="10">
            <ul>
              <li>
                Subtotal
                <span>${{ $utils.keepTwoDecimalFull(subtotal) }}</span>
              </li>
              <li>
                Total
                <span>${{ $utils.keepTwoDecimalFull(subtotal) }}</span>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
      <div class="pay-box">
        <div class="pay-title">Payment Method</div>
        <div class="pay-item">
          <el-radio v-model="payWay" label="1">
            <i class="iconfont icon-weixinzhifuxuanzhong pay-logo"></i>
          </el-radio>
          <div>
            <div>Electronic payment</div>
          </div>
        </div>
      </div>
      <div class="bill-box">
        <div class="bill-title">Billing address</div>
        <div class="bill-item">
          <el-radio v-model="billAdressType" label="1">Same as the shipping address</el-radio>
        </div>
        <div @click="choosePay" class="register-btn yellow">Complete</div>
      </div>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
  import shopHead from "../../components/shop/head";
  import commonFooter from "../../components/common/footer";
  import breadcrumb from "../../components/common/breadcrumb";
  export default {
    components: {
      shopHead,
      commonFooter,
      breadcrumb,
    },
    data() {
      return {
        showAdd: true,
        form: {
          location: "",
          firstName: "",
          lastName: "",
          phone: "",
          detailAddress: "",
        },
        productList: [],
        payWay: "1",
        billAdressType: "1",
        rules: {
          location: [{
            required: true,
            message: " ",
            trigger: "blur",
          }, ],
          firstName: [{
            required: true,
            message: " ",
            trigger: "blur",
          }, ],
          lastName: [{
            required: true,
            message: " ",
            trigger: "blur",
          }, ],
          phone: [{
            required: true,
            message: " ",
            trigger: "blur",
          }, ],
        },
      };
    },
    computed: {
      subtotal() {
        let count = 0;
        this.productList.forEach((item) => {
          count += this.$utils.accMul(item.quantity, item.sellPrice);
        });
        return count;
      },
    },
    mounted() {
      this.initData();
    },
    methods: {
      /** 初始化购物车数据 */
      initData() {
        let arr = [];
        let payItemParts = window.sessionStorage.getItem("payItemParts");
        if (payItemParts) {
          arr = JSON.parse(payItemParts);
          this.productList = arr;
        }
      },
      rand(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
      },
      choosePay() {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let strDate = date.getDate();
        let str = year + (month < 10 ? "0" + month : month) + (strDate < 10 ? "0" + strDate : strDate) + this.rand(1000,
          9999);
        let arr = [];
        if (window.localStorage.getItem("orderData")) {
          arr = JSON.parse(window.localStorage.getItem("orderData"));
        }
        this.productList.forEach((item) => {
          arr.push({
            orderNo: str,
            date: year + "-" + (month < 10 ? "0" + month : month) + "-" + (strDate < 10 ? "0" + strDate :
              strDate),
            img: item.image,
            name: item.partsName,
            code: item.partsNo,
            brand: item.manufacturerCname,
            price: item.sellPrice,
            quantity: item.quantity,
            imgUrl: item.imgUrl,
            imageList: item.imageList,
            amount: this.subtotal,
          });
        });
        window.localStorage.setItem("orderData", JSON.stringify(arr));
        this.$message.success("Payment success");
        this.$router.push({
          path: "/order",
        });
      },
      saveAdd() {
        this.showAdd = !this.showAdd;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .table-img {
    width: 100px;
  }

  .address-box {
    margin: 20px auto 30px;

    ::v-deep .el-form-item {
      margin-bottom: 15px;
    }

    ::v-deep .el-form-item__label {
      font-weight: bold;
      line-height: 24px;
      margin-bottom: 12px;
      padding-bottom: 0;
    }

    ::v-deep .el-input__inner,
    ::v-deep .el-textarea__inner {
      border-radius: 0;

      &:focus {
        border-color: #fed100;
      }
    }
  }

  .address-list {
    display: flex;
    margin: 20px auto 30px;
    align-items: center;

    .address-card {
      width: 340px;
      height: 145px;
      border: 1px solid #ebebeb;
      display: flex;
      align-items: center;

      .address-card-bd {
        width: 5px;
        height: 100%;
        background: url("../../assets/images/border.png") repeat-y;
      }

      .address-card-name {
        line-height: 20px;
        font-size: 16px;
        margin-bottom: 15px;

        .grey {
          font-size: 13px;
          color: #828a9d;
          margin-left: 15px;
        }
      }

      .address-card-text {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .address-btn {
      width: auto;
      height: auto;
      line-height: 20px;
      font-size: 18px;
      text-align: center;
      border: 1px solid #000;
      box-sizing: border-box;
      margin-left: 20px;
      padding: 6px 40px;
      box-sizing: border-box;
      cursor: pointer;
    }
  }

  .cart-area {
    margin-top: 20px;

    ul {
      border: 1px solid #ebebeb;

      li {
        list-style: none;
        font-size: 15px;
        padding: 10px 30px;
        border-bottom: 1px solid #ebebeb;
        font-weight: 700;

        &:last-child {
          border: 0;
        }

        span {
          float: right;
        }
      }
    }
  }

  .pay-box {
    margin-bottom: 30px;

    .pay-title {
      width: 100%;
      line-height: 60px;
      font-size: 24px;
      font-weight: bold;
      border-bottom: 1px solid #ebeef5;
    }

    .pay-item {
      width: 240px;
      height: auto;
      padding: 15px;
      border: 1px solid #ebeef5;
      box-sizing: border-box;
      border-radius: 5px;
      margin-top: 20px;
      display: flex;
      align-items: center;

      ::v-deep .el-radio {
        display: flex;
        align-items: center;
        margin-right: 10px;
      }

      .pay-logo {
        font-size: 26px;
      }

      .text {
        font-size: 12px;
        color: #999;
      }

      ::v-deep .el-radio__input.is-checked+.el-radio__label {
        color: unset;
      }
    }
  }

  .register-btn {
    width: 140px;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    display: block;
    margin: 30px auto 0;
    border-radius: 0;
    border: 0;
    text-transform: uppercase;
    background-color: #333333;
    cursor: pointer;

    &:hover {
      background-color: #fed100;
    }

    &.yellow {
      background-color: #fed100;
      margin: 0;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .bill-box {
    margin-bottom: 40px;
    padding-bottom: 40px;
    position: relative;

    .bill-title {
      width: 100%;
      line-height: 60px;
      font-size: 24px;
      font-weight: bold;
      border-top: 1px solid #ebeef5;
    }

    .bill-item {
      width: 240px;
      height: auto;
      padding: 15px;
      border: 1px solid #ebeef5;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      align-items: center;

      ::v-deep .el-radio__input.is-checked+.el-radio__label {
        color: unset;
      }
    }
  }
</style>
</style>